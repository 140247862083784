import { Flex, HTMLChakraProps } from '@chakra-ui/react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import LogoWord from '@/assets/icons/ic-logo.svg';

import { DropdownMenuLibrary } from '@/components/ui/shared/headers/CoreHeader/components/DropdownMenuLibrary';
import { DropdownMenuSolutions } from '@/components/ui/shared/headers/CoreHeader/components/DropdownMenuSolutions';
import { UserMenu } from '@/components/ui/shared/headers/CoreHeader/components/UserMenu';
import { InstitutionLabel } from '@/components/ui/shared/InstitutionLabel/InstitutionLabel';
import { NavLink } from '@/components/ui/shared/links/NavLink/NavLink';
import {
  CoreNavigation,
  CoreNavigationActions,
  CoreNavigationBrand,
  CoreNavigationClose,
  CoreNavigationHamburger,
  CoreNavigationNav,
  ICoreNavigationProps,
} from '@/components/ui/shared/navigations/CoreNavigation/CoreNavigation';
import { AccessFrom } from '@/enums/AccessFrom';

export interface ICoreHeaderProps extends HTMLChakraProps<'div'> {
  variant?: ICoreNavigationProps['variant'];
  onLogout: () => void;
  accessFrom: AccessFrom;
}

export const CoreHeader: FC<ICoreHeaderProps> = ({ onLogout, variant = 'ghost', accessFrom, ...rest }) => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <CoreNavigation variant={variant} {...rest}>
      <CoreNavigationHamburger title={t('action.openMenu')} aria-label={t('action.openMenu')} />

      <CoreNavigationBrand w={{ base: 'auto', lg: 'full' }}>
        <NextLink href="/" aria-label="Go to the homepage" passHref>
          <LogoWord css={{ height: '1.25rem' }} />
        </NextLink>
        <InstitutionLabel />
      </CoreNavigationBrand>

      <CoreNavigationNav>
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          mt={{ base: 5, lg: 0 }}
          alignItems={{ base: 'flex-start', lg: 'center' }}
        >
          <CoreNavigationClose title={t('action.closeMenu')} aria-label={t('action.closeMenu')} />

          <DropdownMenuLibrary />

          <NextLink href="/library/events-list?status=upcoming&accessFrom=events" passHref legacyBehavior>
            <NavLink isActiveLink={router.asPath.startsWith('/library/events-list?status=upcoming&accessFrom=events')}>
              {t('eventsPage.navigationLabel')}
            </NavLink>
          </NextLink>

          <DropdownMenuSolutions />

          <CoreNavigationActions>
            <UserMenu accessFrom={accessFrom} onLogout={onLogout} />
          </CoreNavigationActions>
        </Flex>
      </CoreNavigationNav>
    </CoreNavigation>
  );
};
