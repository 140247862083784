import { Button, Flex, Menu, MenuButton, MenuButtonProps, MenuItem, MenuList } from '@chakra-ui/react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';

import ArrowIcon from '@/assets/icons/ic-down-chevron.svg';

import { INavigationItems } from '@/components/ui/shared/headers/CoreHeader/components/constants';

interface ICoreNavigationMenuDropdownDesktopProps extends MenuButtonProps {
  navigationTitle: string;
  navigationItems: Array<INavigationItems>;
}

export const MenuDropdownDesktop: FC<ICoreNavigationMenuDropdownDesktopProps> = ({
  navigationTitle,
  navigationItems,
  ...rest
}) => {
  const router = useRouter();

  return (
    <Flex display={{ base: 'none', lg: 'block' }}>
      <Menu computePositionOnMount variant="navigation" colorScheme="blackAlpha" placement="bottom-end" {...rest}>
        <MenuButton
          boxShadow={0}
          borderRadius={0}
          fontSize={{ base: '16px' }}
          fontWeight={700}
          px={4}
          py={4}
          _hover={{ textDecoration: 'underline' }}
          _focus={{ boxShadow: 'none', background: 'transparent' }}
          as={Button}
          rightIcon={<ArrowIcon color="inherit" />}
          color="inherit"
          background="transparent"
          _active={{ background: 'transparent' }}
        >
          {navigationTitle}
        </MenuButton>
        <MenuList color="white">
          {navigationItems &&
            navigationItems?.map((item: INavigationItems) => (
              <MenuItem
                textDecoration={router.asPath.startsWith(item.href) ? 'underline' : null}
                _activeLink={{ color: 'red' }}
                as={NextLink}
                href={item.href}
                key={item.id}
              >
                {item.title}
              </MenuItem>
            ))}
        </MenuList>
      </Menu>
    </Flex>
  );
};
