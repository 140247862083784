import { Button, Flex, FlexProps } from '@chakra-ui/react';
import NextLink from 'next/link';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { NavLink } from '@/components/ui/shared/links/NavLink/NavLink';
import { selfServiceEventsURL } from '@/constants/self-service-dashboard-routes';
import { AccessFrom } from '@/enums/AccessFrom';
import { User } from '@/resources/User';

interface ICoreNavigationAuthenticatedMobileProps extends FlexProps {
  currentUser: User;
  onLogout?: () => void;
  accessFrom: AccessFrom;
}

export const CoreNavigationAuthenticatedMobile: FC<ICoreNavigationAuthenticatedMobileProps> = ({
  currentUser,
  onLogout,
  accessFrom,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Flex {...rest} flexDirection="column">
      <NextLink href={`/my-account/profile?accessFrom=${accessFrom}`} passHref legacyBehavior>
        <NavLink textTransform="lowercase">{t('action.myProfile')}</NavLink>
      </NextLink>
      {currentUser.isEventManager && (
        <NavLink href={selfServiceEventsURL} textTransform="lowercase">
          {t('action.myOrganizedEvents')}
        </NavLink>
      )}
      <NavLink textTransform="lowercase" onClick={onLogout}>
        {t('action.logOut')}
      </NavLink>
    </Flex>
  );
};
