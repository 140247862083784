import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { MenuDropdownDesktop } from '@/components/ui/shared/headers/GeneralDropdown/MenuDropdown.desktop';
import { MenuDropdownMobile } from '@/components/ui/shared/headers/GeneralDropdown/MenuDropdown.mobile';

const data = [
  {
    id: 1,
    title: 'for event organizers',
    href: '/events',
  },
  {
    id: 2,
    title: 'for librarians',
    href: '/librarians',
  },
];

export const DropdownMenuSolutions: FC = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <MenuDropdownDesktop navigationTitle={t('navigation.solutions')} navigationItems={data} />
      <MenuDropdownMobile navigationTitle={t('navigation.solutions')} navigationItems={data} />
    </Fragment>
  );
};
