import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { useResources } from 'datx-jsonapi-react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';

import ArrowIcon from '@/assets/icons/ic-down-chevron.svg';

import { LIBRARY_MENU_ITEMS, INavigationItems } from '@/components/ui/shared/headers/CoreHeader/components/constants';
import { queryAllOriginalsSeries } from '@/queries/originals-series';
import { OriginalsSeries } from '@/resources/OriginalsSeries';

export const LibraryMenuDesktop: FC = () => {
  const { data } = useResources(queryAllOriginalsSeries({ collection: 'false' }));
  const router = useRouter();

  function getHref(original: OriginalsSeries) {
    return `/originals/${original.id}-${original.slug}`;
  }

  function isSelected(item: INavigationItems) {
    let doesNotStart = true;

    if (item.notStart) {
      doesNotStart = !router.asPath.startsWith(item.notStart);
    }
    return router.asPath.startsWith(item.href) && doesNotStart ? 'underline' : null;
  }

  return (
    <Flex display={{ base: 'none', lg: 'block' }}>
      <Menu computePositionOnMount variant="navigation" colorScheme="blackAlpha" placement="bottom-end">
        <MenuButton
          boxShadow={0}
          borderRadius={0}
          fontSize={{ base: '16px' }}
          fontWeight={700}
          px={4}
          py={4}
          _hover={{ textDecoration: 'underline' }}
          _focus={{ boxShadow: 'none', background: 'transparent' }}
          as={Button}
          rightIcon={<ArrowIcon color="inherit" />}
          color="inherit"
          background="transparent"
          _active={{ background: 'transparent' }}
        >
          library
        </MenuButton>
        <MenuList color="white">
          {LIBRARY_MENU_ITEMS.slice(0, 3).map((item: INavigationItems) => (
            <MenuItem
              textDecoration={isSelected(item)}
              _activeLink={{ color: 'red' }}
              as={NextLink}
              href={item.href}
              key={item.id}
            >
              {item.title}
            </MenuItem>
          ))}
          <Accordion allowMultiple>
            <AccordionItem border="none">
              <AccordionButton
                display="flex"
                justifyContent="space-between"
                _hover={{ background: 'gray.800' }}
                py={3}
                fontWeight="semibold"
              >
                originals
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel display="flex" flexDir="column" p={0}>
                {(data || []).map((original) => (
                  <Text
                    as={NextLink}
                    fontFamily="heading"
                    fontWeight="semibold"
                    tabIndex={-1}
                    href={getHref(original)}
                    key={original.id}
                    pl={7}
                    py={3}
                    _hover={{ background: 'gray.800' }}
                  >
                    {original.author.firstName} {original.author.lastName}
                  </Text>
                ))}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          {LIBRARY_MENU_ITEMS.slice(3).map((item: INavigationItems) => (
            <MenuItem
              textDecoration={isSelected(item)}
              _activeLink={{ color: 'red' }}
              tabIndex={-1}
              as={NextLink}
              href={item.href}
              key={item.id}
            >
              {item.title}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  );
};
