import { FC, Fragment } from 'react';

import { LibraryMenuDesktop } from '@/components/ui/shared/headers/CoreHeader/components/LibraryMenu.desktop';
import { LibraryMenuMobile } from '@/components/ui/shared/headers/CoreHeader/components/LibraryMenu.mobile';

export const DropdownMenuLibrary: FC = () => {
  return (
    <Fragment>
      <LibraryMenuDesktop />
      <LibraryMenuMobile />
    </Fragment>
  );
};
